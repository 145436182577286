
<template>
    <div v-if="currentUserRole == 'student'">
        <div class="tableWrapper" style="margin-bottom: 15px;" v-if="documentList.length > 0 && !isSpinner">
            <div v-for="(data,index) in documentList" :key="index">
                <div class="mainDivWrapper_app">
                    <div class="titleImageWraapper_app">
                        <span class="messageTitle">{{ data.title }}</span>
                        <img src="../../../assets/images/erflog/Close_Red.png" class="closeReply cursor-pointer" @click="dismiss(data)"/>
                    </div>
                    <span class="messageDiscripetion" :title="data.discription">{{ data.discription }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from "@/utils/firebaseInit.js";
import { dbCollections } from "@/utils/firebaseCollection.js";
import Ripple from 'vue-ripple-directive'
import {
 BButton
} from 'bootstrap-vue'
const db = firebase.firestore() 
export default {
    directives: {
        Ripple,
    },
    components: {
        BButton
    },
    data() {
    return {
        isVisible: true,
        documentList: [],
        currentUserRole: "",
        isSpinner: false
    };
    },
    created(){
        let self = this;
        self.$root.$on("noticePopupOpen", (data) => {
            self.isVisible = data;
        });
        self.isSpinner = true;
        const user = JSON.parse(localStorage.getItem("currentUser"));
        this.currentUserRole = user.role;
        db.collection(dbCollections.APPLICATIONNOTES).where("isDeleted",'==',false)
                .onSnapshot({includeMetadataChanges: true},(snapshot) => {
                if (snapshot.empty) {
                    this.isSpinner = false;
                    self.documentList = [];
                    return;
                }
                snapshot.docChanges({includeMetadataChanges: true}).forEach((change) => {
                    if(change.type === "added") {
                        if(change.doc.data().status == 1) {
                            this.isVisible = true;
                        }
                        let index = self.documentList.findIndex((ele)=>{
                            return ele.id == change.doc.data().id
                        })
                        if(index > -1) {
                            let obj = {...change.doc.data()};
                            self.documentList[index] = obj
                        } else {
                            if(!change.doc.metadata.hasPendingWrites) {
                                let obj = {...change.doc.data()};
                                self.documentList.push(obj);
                            }
                        }
                    }
                    if(change.type === "modified") {
                        if(change.doc.data().status = 1) {
                            this.isVisible = true;
                        }
                        let index = self.documentList.findIndex((x) => x.id === change.doc.id);
                        if(index > -1) {
                            self.documentList[index] = {...change.doc.data()};
                        } 
                        else {
                            let obj = {...change.doc.data()};
                            self.documentList.push({...obj});
                        }
                    }
                    if(change.type === "removed" && !change.doc.metadata.hasPendingWrites) {
                        let index = self.documentList.findIndex((x) => x.id === change.doc.id);
                        if(index > -1) {
                            self.documentList.splice(index, 1);
                        }
                    }
                })
                self.documentList.sort((a,b)=>{
                    return b.createdAt.seconds - a.createdAt.seconds
                })
                self.$nextTick(()=>{
                    self.documentList = self.documentList.filter((ele)=>{
                        return ele.status == 1 && ele.isDeleted == false
                    })
                    self.$forceUpdate();
                    self.isSpinner = false
                })
            })
    },
    methods: {
        dismiss(data) {
            let self = this;
            let fIndex = self.documentList.findIndex((ele)=>{
                return ele.id == data.id
            })
            if(fIndex > -1) {
                self.documentList.splice(fIndex, 1);
            }
        },
    },
};
</script>

<style>
.btnAttachmtn{
    text-align: right;
}
.titleImageWraapper_app{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.mainDivWrapper_app{
    padding: 10px 20px;
    border: 1px solid #00aeed;
    display: flex;
    border-radius: 0.428rem;
    flex-direction: column;
    margin-bottom: 2px;
    background-color: #fff;
}
.text-elipsis{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.noDataFound {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}
.noDataFoundText {
    font-size: 14px;
    font-weight: 600;
    color: #0d4ea0;
}
.tableWrapper::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: #CFCFCF;
    border-radius: 6px;
}
.tableWrapper::-webkit-scrollbar-thumb {
    background: #0d4ea0 !important;
    border-radius: 6px;
}
.tableWrapper{
    overflow: auto;
    max-height: 300px;
    padding: 0 10px 0 0;
}
.tableMain {
    width: 100%;
    border-collapse: collapse;
}
.tableMain tr td {
    padding: 7px;
    border-bottom: 1px solid #ccc;
}
.messageTitle{
    font-size: 15px;
    font-weight: 700;
    width: 40%;
}
.messageDiscripetion{
    font-size: 14px;
    font-weight: 400;
    /* width: 60%; */
}
.cursor-pointer {
    cursor: pointer;
}
</style>