<template>
<div>
    <div v-show="!isLoading">
         <!-- APPLICATION MANAGEMENT -->   
        <b-row class="mt-0">
        <!-- <b-col md="12" class="d-flex align-items-center justify-content-end mb-1 mb-md-0">    
          <div class="d-flex status_top_line">
            <ul class="d-flex align-items-center">
              <li><span class="panding-bg round_small_status"></span><span>Pending</span></li>
              <li><span class="inprogress-bg round_small_status"></span><span>Inprogress</span></li>
              <li><span class="ad_success round_small_status"></span><span>Application Successful</span></li>
              <li><span class="ad_rejected round_small_status"></span><span>Application Rejected</span></li>
            </ul>
          </div>  
        </b-col>  -->
        <b-col cols="12" md="12">
          <applicationNoteManagement/>
        </b-col>
          <b-col cols="12" md="12" class="d-flex align-items-center justify-content-end mt-1 mb-md-0">
                <div class="d-flex status_top_line">
                    <ul class="d-flex flex-wrap align-items-center">
                      <li><span class="panding-bg round_small_status"></span><span>Pending</span></li>
                      <li><span class="noteligible-bg round_small_status"></span><span>Not Eligible</span></li>
                      <li><span class="inprogress-bg round_small_status"></span><span>In progress</span></li>
                      <li><span class="paymentPending-bg round_small_status"></span><span>Payment Pending</span></li>
                      <li><span class="paymentSuccessfull-bg round_small_status"></span><span>Payment Successful</span></li>
                      <li><span class="applicationSubmitted-bg round_small_status"></span><span>Application Submitted</span></li>
                      <li><span class="ad_success round_small_status"></span><span>Admission Offered</span></li>
                      <li><span class="ad_rejected round_small_status"></span><span>Rejection</span></li>
                    </ul>
                </div>
            </b-col>
       <b-col md="12" class="mt-2">
           <div class="card mb-0 pb-2">
            <b-table
            striped
            hover
            responsive           
            :items="studentApplication"
            :fields="columns" 
            :show-empty="!isSpinner"
            empty-text="No matching records found"
            @row-clicked="handleRowClick"       
            no-local-sorting
            @sort-changed="handleSortChange"
            :current-page="1"
            v-model="docCategoryTable"     
            class="position-relative mt-1 application_mgmt_table padding-custom"
           >
           <template #cell(applicationNumber)="data"> 
             <div class="add_edu_link">
               <a @click.prevent="handleRowClick(data.item)">
                {{data.item.applicationNumber}}
               </a>
             </div>
           </template>
           <template #cell(selectedCollege)="data"> 
               {{data.item.selectedCollege.length}}
           </template>
           <template #cell(displayStatus)="data">
             <b-badge :variant="'light-'+data.item.applicationStatusVariant" :class="[{'pendingCSSSClass':data.item.displayStatus === 'Payment Pending','applicationSubmitted' : data.item.displayStatus === 'Application Submitted','applicationNotEligibleStudentDetails' : data.item.displayStatus === 'Not Eligible'}]">
                {{data.item.displayStatus}}  
             </b-badge> 
              <!-- <span :class="{'badge badge-primary': (data.item.application_Status.toLowerCase()=='pending'), 'badge badge-success': (data.item.application_Status.toLowerCase()=='admissionsuccessful'), 'badge badge-warning':(data.item.application_Status.toLowerCase()=='inprogress'), 'badge badge-danger':(data.item.application_Status.toLowerCase()=='admissionrejected')}">
               {{data.item.displayStatus}}
              </span> -->
           </template>
           <template #cell(shortListStatus)="data"> 
                <div>
                   <div class="d-flex status_top_line">
                        <ul class="d-flex align-items-center">
                          <li><span class="panding-bg round_small_status"></span><span>{{data.item.shortListStatus.totalPending}}</span></li>
                          <li><span class="noteligible-bg round_small_status"></span><span>{{data.item.shortListStatus.totalNotEligible}}</span></li>
                          <li><span class="inprogress-bg round_small_status"></span><span>{{data.item.shortListStatus.totalInProgress}}</span></li>
                          <li><span class="paymentPending-bg round_small_status"></span><span>{{data.item.shortListStatus.total_pay_pen}}</span></li>
                          <li><span class="paymentSuccessfull-bg round_small_status"></span><span>{{data.item.shortListStatus.total_pay_succ}}</span></li>
                          <li><span class="applicationSubmitted-bg round_small_status"></span><span>{{data.item.shortListStatus.totalApplicationSub}}</span></li>
                          <li><span class="ad_success round_small_status"></span><span>{{data.item.shortListStatus.totalAdmissionSuccesfull}}</span></li>
                          <li><span class="ad_rejected round_small_status"></span><span>{{data.item.shortListStatus.totalAdmissionRejected}}</span></li>
                        </ul>
                    </div>
                </div>
           </template>
            </b-table>
              <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                      <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ totalData }} entries</span>
              </b-col>
                <b-col cols="12" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="totalData"
                      :per-page="perPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      @change="handlePageChange"
                      prev-class="prev-item"
                      next-class="next-item"/>
                </b-col>
            </div>
        </b-col>
        </b-row> 
        <b-spinner variant="primary" v-if="isSpinner" large class="pageLoadSpinners"/>
    </div>
    <b-spinner variant="primary" v-if="isLoading" large class="pageLoadSpinners"/>
</div>
</template>
<script>
import {
  BRow, BCol, BTable, BSpinner, BBadge, BPagination
} from 'bootstrap-vue'
import { dbCollections } from '@/utils/firebaseCollection'
import * as getQuery from '@/utils/FirebaseQueries/getQueries/getQueries.js'
import moment from 'moment'
import applicationNoteManagement from '@/views/apps/student/applicationNoteManagement.vue'
import { getCollectionDataTypesense } from '@/utils/Typesense/queries';

import firebase from '@/utils/firebaseInit'

const db = firebase.firestore()
export default {
  components: {   
        BRow,
        BCol,
        BTable,
        BSpinner,
        BBadge,
        applicationNoteManagement,
        BPagination
  },
  computed: {
     dataMeta(){
        const localItemsCount = this.docCategoryTable ? this.docCategoryTable.length : 0
        return {
            from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
            to: this.perPage * (this.currentPage - 1) + localItemsCount,
            of: this.applicationData,
        }
      }  
  },
  data(){
      return{
         studentApplication:[],
         columns: [
              {key: 'applicationNumber',label:'Application No.', sortable: true},
              {key:'selectedCollege',label:'No of Suggested Course', sortable: false},
              {key:'shortlistCollege',label:'No of Shortlist Course', sortable: false},
              {key:'applyDate',label:'Application Date', sortable: true},
              {key:'displayStatus',label:'Application Status', sortable: true},
              {key:'shortListStatus',label:''},
          ],
          isSpinner:false, //save data spinner
          isLoading:false, //page fet data loader
          perPage:10,
          currentPage:1,
          totalRows:0,
          docCategoryTable:null,
      }
  },
  watch: {
    $route: function () {
        // this.init();    
        this.fetchApplicationDataTypesense();      
    }
  },
  created(){
      // this.init();
      this.fetchApplicationDataTypesense();
  },
  methods:{
      handleSortChange(context) {
          let sortFieldObject = {
              displayStatus : "application_Status",
              applicationNumber : "applicationNumber",
              applyDate : "createdAt",
              selectedCollege : "",
              shortListStatus : "",
          }
          const {sortBy,sortDesc} = context
          this.sortContect = {sortBy:sortFieldObject[sortBy],sortDesc};
          this.fetchApplicationDataTypesense(false)
      },
      handlePageChange(value) {
          this.currentPage = value;
          this.fetchApplicationDataTypesense(false)
      },
      setTableData(res,cb){
             this.studentApplication = [];
              if(res.length > 0){
                res.forEach((element,index) => {
                      if(element.isDeleted == false){
                          element.shortlistCollege = 0;
                          if(element.shortListedColleges !== undefined && element.shortListedColleges.length > 0){
                              element.shortlistCollege = element.shortListedColleges.length 
                          }
                          element.applyDate = moment(element.createdAt.toDate()).format('DD MMM, YYYY') 
                          element.shortListStatus= {
                            totalPending:0,
                            totalInProgress:0,
                            totalNotEligible: 0,
                            total_pay_pen:0,
                            total_pay_succ:0,
                            totalApplicationSub:0,
                            totalAdmissionSuccesfull:0,
                            totalAdmissionRejected:0
                         }
                         if(element.shortListedColleges !== undefined && element.shortListedColleges.length > 0){                  
                            element.shortListedColleges.forEach(shortlisted=>{
                                if(shortlisted.status == 'pending'){
                                element.shortListStatus.totalPending = Number(JSON.parse(element.shortListStatus.totalPending) + 1);
                                }
                                if(shortlisted.status == 'inProgress'){
                                element.shortListStatus.totalInProgress = Number(JSON.parse(element.shortListStatus.totalInProgress) + 1);
                                }
                                if(shortlisted.status == 'paymentPending'){
                                element.shortListStatus.total_pay_pen = Number(JSON.parse(element.shortListStatus.total_pay_pen) + 1);
                                }
                                if(shortlisted.status == 'paymentSuccessful'){
                                element.shortListStatus.total_pay_succ = Number(JSON.parse(element.shortListStatus.total_pay_succ) + 1);
                                }
                                if(shortlisted.status == 'applicationSubmitted'){
                                element.shortListStatus.totalApplicationSub = Number(JSON.parse(element.shortListStatus.totalApplicationSub) + 1);
                                }
                                if(shortlisted.status == 'admissionSuccessful'){
                                element.shortListStatus.totalAdmissionSuccesfull = Number(JSON.parse(element.shortListStatus.totalAdmissionSuccesfull) + 1);
                                }
                                if(shortlisted.status == 'admissionRejected'){
                                element.shortListStatus.totalAdmissionRejected = Number(JSON.parse(element.shortListStatus.totalAdmissionRejected) + 1);
                                }                 
                                if(shortlisted.status == 'notEligible'){
                                element.shortListStatus.totalNotEligible = Number(JSON.parse(element.shortListStatus.totalNotEligible) + 1);
                                }                 
                            })
                          }
                          if(element.application_Status.toLowerCase()=='pending'){
                              element.displayStatus = 'Pending'
                              element.applicationStatusVariant = 'primary'
                          }
                          if(element.application_Status.toLowerCase()=='inprogress'){
                              element.displayStatus = 'In Progress'
                              element.applicationStatusVariant = 'warning'
                          }
                          if(element.application_Status.toLowerCase()=='paymentpending'){
                              element.displayStatus = 'Payment Pending'
                              element.applicationStatusVariant = 'secondary'
                          }
                          if(element.application_Status.toLowerCase()=='paymentsuccessful'){
                              element.displayStatus = 'Payment Successful'
                              element.applicationStatusVariant = 'info'
                          }
                          if(element.application_Status.toLowerCase()=='applicationsubmitted'){
                              element.displayStatus = 'Application Submitted'
                              element.applicationStatusVariant = 'dark'
                          }
                          if(element.application_Status.toLowerCase()=='admissionsuccessful'){
                              element.displayStatus = 'Admission Offered'
                              element.applicationStatusVariant = 'success'
                          }
                          if(element.application_Status.toLowerCase()=='admissionrejected'){
                              element.displayStatus = 'Rejection'
                              element.applicationStatusVariant = 'danger'
                          }     
                          if(element.application_Status.toLowerCase()=='noteligible'){
                              element.displayStatus = 'Not Eligible'
                              element.applicationStatusVariant = 'dark'
                          }                      
                          this.studentApplication.push(element)
                      }
                      if(index == Number(res.length - 1)){
                          cb(true)
                      }
                  });
              }else{
                cb(true)
              }
      },
      init(){
          let currentUser = JSON.parse(localStorage.getItem('currentUser'))
          this.isLoading = true;

          db
          .collection(dbCollections.APPLICATIONS)
          .orderBy('createdAt','desc')
          .where('selectedStudent.id','==',currentUser.id)
          .onSnapshot((querySnapshot)=>{
            var res = []
            querySnapshot.forEach(doc=>{
              res.push(doc.data())
            })

            this.isLoading = true;
              let currentStudeApp = [];
              console.log(res,"SEE HERE")
              if(res.length > 0){
                  res.forEach(element => {
                    if(element.isDeleted == false){
                      if(Object.keys(element.selectedStudent).length > 0){                         
                          if(element.selectedStudent.id == currentUser.id){
                            currentStudeApp.push(element)
                          }
                      }
                    }
                  });                  
                  this.setTableData(currentStudeApp,cb=>{
                    if(cb==true){  
                    this.isLoading = false;
                    }
                  })  
              }else{
                this.isLoading = false;
              }
          })

          // getQuery.getDataWithSpecificWhereConditionAndOrderBy(dbCollections.APPLICATIONS,res=>{
          //     this.isLoading = true;
          //     let currentStudeApp = [];
          //     console.log(res,"SEE HERE")
          //     if(res.length > 0){
          //         res.forEach(element => {
          //           if(element.isDeleted == false){
          //             if(Object.keys(element.selectedStudent).length > 0){                         
          //                 if(element.selectedStudent.id == currentUser.id){
          //                   currentStudeApp.push(element)
          //                 }
          //             }
          //           }
          //         });                  
          //         this.setTableData(currentStudeApp,cb=>{
          //           if(cb==true){  
          //           this.isLoading = false;
          //           }
          //         })  
          //     }else{
          //       this.isLoading = false;
          //     }
          // })
        //   this.isSpinner = false;
      },
      fetchApplicationDataTypesense(resetResult = true){
            try {
                let currentUser = JSON.parse(localStorage.getItem('currentUser'))
                var self = this;
                if (resetResult) {
                    self.currentPage = 1;
                    self.totalData = 0;
                }

                let searchParameters = {
                    collectionName: `${dbCollections.APPLICATIONS}`,
                    search: {
                        q:"*",
                        filter_by: `selectedStudent.id:=${currentUser.id}`,
                        page: self.currentPage,
                        per_page: self.perPage,
                    }
                }

                if (this.sortContect) {
                    searchParameters.search.sort_by = `${(this.sortContect.sortBy && this.sortContect.sortBy !== '') ? `${this.sortContect.sortBy}: ${this.sortContect.sortDesc == false ? 'asc' : 'desc'}` : ''}`;
                }

                getCollectionDataTypesense(searchParameters).then((result)=>{
                    if(result.hits.length) {
                        self.totalData = result.found
                        const arrayData = result.hits.map(x => {
                            const applicationObject = x.document;
                            let elements = {};
                            let displayStatus = 'Pending'
                            let applicationStatusVariant = 'primary'
                            elements.totalPending = 0;
                            elements.totalNotEligible = 0;
                            elements.total_pay_pen = 0;
                            elements.total_pay_succ = 0;
                            elements.totalApplicationSub = 0;
                            elements.totalInProgress = 0;
                            elements.totalAdmissionSuccesfull = 0;
                            elements.totalAdmissionRejected = 0;
                            elements.displayStatus = '';
                            if(applicationObject.application_Status.toLowerCase()=='pending'){
                                displayStatus = 'Pending'
                                applicationStatusVariant = 'primary'
                            }
                            if(applicationObject.application_Status.toLowerCase()=='inprogress'){
                                displayStatus = 'In Progress'
                                applicationStatusVariant = 'warning'
                            }
                            if(applicationObject.application_Status.toLowerCase()=='paymentpending'){
                                displayStatus = 'Payment Pending'
                                applicationStatusVariant = 'secondary'
                            }
                            if(applicationObject.application_Status.toLowerCase()=='paymentsuccessful'){
                                displayStatus = 'Payment Successful'
                                applicationStatusVariant = 'info'
                            }
                            if(applicationObject.application_Status.toLowerCase()=='applicationsubmitted'){
                                displayStatus = 'Application Submitted'
                                applicationStatusVariant = 'dark'
                            }
                            if(applicationObject.application_Status.toLowerCase()=='admissionsuccessful'){
                                displayStatus = 'Admission Offered'
                                applicationStatusVariant = 'success'
                            }
                            if(applicationObject.application_Status.toLowerCase()=='admissionrejected'){
                                displayStatus = 'Rejection'
                                applicationStatusVariant = 'danger'
                            }            
                            if(applicationObject.application_Status.toLowerCase()=='noteligible'){
                                displayStatus = 'Not Eligible'
                                applicationStatusVariant = 'dark'
                            }            
                            //COUNT SHORTLIST STATUS
                            if(applicationObject.shortListedColleges !== undefined){

                                applicationObject.shortListedColleges.forEach(valuess=>{
                                    if(valuess){ 
                                        if(valuess.status == 'pending'){
                                        elements.totalPending = Number(JSON.parse(elements.totalPending) + 1);
                                        }
                                        if(valuess.status == 'inProgress'){
                                        elements.totalInProgress = Number(JSON.parse(elements.totalInProgress) + 1);
                                        }
                                        if(valuess.status == 'admissionSuccessful'){
                                        elements.totalAdmissionSuccesfull = Number(JSON.parse(elements.totalAdmissionSuccesfull) + 1);
                                        }
                                        if(valuess.status == 'admissionRejected'){
                                        elements.totalAdmissionRejected = Number(JSON.parse(elements.totalAdmissionRejected) + 1);
                                        } 
                                        if(valuess.status == 'paymentPending'){
                                            elements.total_pay_pen = Number(JSON.parse(elements.total_pay_pen) + 1);
                                        }
                                        if(valuess.status == 'paymentSuccessful'){
                                            elements.total_pay_succ = Number(JSON.parse(elements.total_pay_succ) + 1);
                                        }
                                        if(valuess.status == 'applicationSubmitted'){
                                            elements.totalApplicationSub = Number(JSON.parse(elements.totalApplicationSub) + 1);
                                        }
                                        if(valuess.status == 'notEligible'){
                                            elements.totalNotEligible = Number(JSON.parse(elements.totalNotEligible) + 1);
                                        }
                                    }
                                })
                            }


                            return {
                                ...applicationObject,
                                id: applicationObject.id,
                                applyDate: applicationObject.createdAt ? moment(new Date(applicationObject.createdAt * 1000)).format('DD MMM, yyyy') : '',
                                studentName: applicationObject.selectedStudent.name,
                                shortlistCollege: applicationObject?.shortListedColleges?.length || 0,
                                shortListStatus: elements,
                                displayStatus,
                                applicationStatusVariant
                            };
                        });

                        this.studentApplication = arrayData;

                    } else {
                        this.studentApplication = [];
                        self.totalData = 0;
                    }
                }).catch((error)=>{
                    this.studentApplication = [];
                    self.totalData = 0;
                    console.error(error)
                })
            } catch (error) {
                this.studentApplication = [];
                self.totalData = 0;
                console.error(error)
            }
        },
      handleRowClick(data){
          this.$router.push({name:'studentapplicationDetails',params: { id: data.id,name:data.applicationNumber }})
      }
  }
}
</script>
<style>
.applicationNotEligibleStudentDetails {
    border-color: #dfecec!important;
    background-color: #dfecec!important;
    color: #396060!important;
    padding: 8px 10px !important;
    border-radius: 10px !important;
}
</style>
